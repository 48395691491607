import { Injectable } from '@angular/core';
import { UnknownError, ConnectionError } from '@app/shared/util/errors/error';
import { Failure, Result, Success } from '@app/shared/util/types/result';
import { Network } from '@capacitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, shareReplay, take } from 'rxjs/operators';

//   enum PlacesServiceStatus {
//     INVALID_REQUEST = 'INVALID_REQUEST',
//     NOT_FOUND = 'NOT_FOUND',
//     OK = 'OK',
//     OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
//     REQUEST_DENIED = 'REQUEST_DENIED',
//     UNKNOWN_ERROR = 'UNKNOWN_ERROR',
//     ZERO_RESULTS = 'ZERO_RESULTS',
// }

@Injectable({ providedIn: 'root' })
export class GoogleApiRepository {
  private hasLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly hasLoaded$: Observable<boolean> = this.hasLoadedSubject.pipe(shareReplay(1));

  init() {
    this.hasLoadedSubject.next(true);
  }

  waitUntilLoaded(): Promise<boolean> {
    return new Promise((resolve) => {
      this.hasLoaded$
        .pipe(
          filter((loaded) => loaded === true),
          take(1)
        )
        .subscribe((_) => resolve(true));
    });
  }

  async nearbyPlaces(
    map: google.maps.Map,
    lat: number,
    lon: number
  ): Promise<Result<google.maps.places.PlaceResult[]>> {
    if (this.hasLoadedSubject.value !== true) {
      await this.waitUntilLoaded();
    }

    const location = new google.maps.LatLng(lat, lon);

    const service = new google.maps.places.PlacesService(map);
    const request: google.maps.places.PlaceSearchRequest = {
      location,
      // radius: 2000,
      rankBy: google.maps.places.RankBy.DISTANCE,
      type: 'pet_store',
    };
    const result = new Promise((resolve, reject) => {
      service.nearbySearch(request, async (results, status, pagination) => {
        if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          try {
            const networkStatus = await Network.getStatus();
            if (networkStatus.connected) {
              resolve(new Success([]));
            } else {
              reject(new Failure(new ConnectionError()));
            }
          } catch (error) {
            reject(new Failure(new UnknownError()));
          }
        } else if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(new Success(results));
        } else {
          reject(new Failure(new UnknownError()));
        }
      });
    }) as Promise<Result<google.maps.places.PlaceResult[]>>;
    return await result;
  }

  async reverseGeocode(args: {
    map: google.maps.Map;
    lat: number;
    lon: number;
  }): Promise<Result<google.maps.GeocoderResult>> {
    const { map, lat, lon } = args;
    if (this.hasLoadedSubject.value !== true) {
      await this.waitUntilLoaded();
    }
    const location = new google.maps.LatLng(lat, lon);
    const geocoder = new google.maps.Geocoder();

    return await new Promise((resolve) =>
      geocoder.geocode({ location }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            resolve(new Success(results[0]));
            // const marker = new google.maps.Marker({
            //   position: results[0].geometry.location,
            //   map,
            // });
          } else {
            resolve(new Failure(new UnknownError()));
          }
        } else {
          resolve(new Failure(new UnknownError()));
        }
      })
    );
  }
}
