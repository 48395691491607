import { BaseQuery } from '@app/domain/base/query';
import { snakeCase } from 'snake-case';

interface QueryParams {
  [key: string]: string | string[];
}

export function queryToParams(query: Partial<BaseQuery>): QueryParams {
  if (query == null) return {};
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (value == null || value === '') return acc;

    key = snakeCase(key);
    if (Array.isArray(value) && value.length > 0) {
      acc[key] = value.map(valueToString).join(',');
    } else {
      if (key === 'page') value = +value + 1;
      if (key === 'sort') {
        key = 'ordering';
        value = snakeCase(value.toString());
      }
      acc[key] = value;
    }
    return acc;
  }, {});
}

function valueToString(v: unknown): string {
  if (v == null) return null;
  if (typeof v === 'string') return v;
  if (v.toString != null) return v.toString();
  return null;
}
