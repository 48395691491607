import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/api/auth.service';
import { ServerErrorsService } from '@app/shared/services/server-errors.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { Result } from '@app/shared/util/types/result';
import { Keyboard } from '@capacitor/keyboard';
import { AuthUrlBuilder } from '../auth-url-builder';
import { Platform } from '@ionic/angular';

@Injectable()
export class SignUpService {
  public signUpTask: Result;

  constructor(
    private platform: Platform,
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private serverErrorsService: ServerErrorsService
  ) {}

  async signUp(form: any) {
    const spinner = await this.spinnerService.showSpinner();
    form.password2 = form.password1;
    this.signUpTask = await this.authService.signUp(form);

    if (this.signUpTask.isSuccess) {
      if (this.platform.is('pwa')) {
        await Keyboard.hide();
      }
      await spinner.dismiss();
      await this.router.navigate([AuthUrlBuilder.signUpSuccess()], { replaceUrl: true });
    } else {
      await this.serverErrorsService.showErrors(
        this.signUpTask.error,
        'Não foi possível fazer o Registro. Tente novamente.'
      );
    }
    await spinner.dismiss();
  }
}
