export function blobToFile(theBlob: Blob, fileName: string): File {
  const typeChunks = theBlob.type.split('/');
  const type = typeChunks[typeChunks.length - 1];
  const name = `${fileName}.${type}`;

  // Cast to a File() type
  return new File([theBlob], name);
}

export function getImageUrl(files: FileList): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      reject();
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      resolve(reader.result);
    };
    reader.onerror = (_) => reject();
  });
}

export class FileForm {
  constructor(private readonly url: string | ArrayBuffer, public readonly file: File = null) {}

  static async fromFile(files: FileList) {
    const url = await getImageUrl(files);
    return new FileForm(url, files[0]);
  }
}
