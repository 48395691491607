import { NgModule } from '@angular/core';
import { DateDiffPipe } from './date-diff.pipe';
import { AddressPipe } from './address.pipe';
import { NewLineToBRPipe } from './new-line-to-br.pipe';

@NgModule({
  declarations: [DateDiffPipe, AddressPipe, NewLineToBRPipe],
  exports: [DateDiffPipe, AddressPipe, NewLineToBRPipe],
})
export class PipesModule {}
