import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StatusBarService } from '@app/shared/services/status-bar.service';
import { LoginService } from '@auth/login/login.service';
import { AuthUrlBuilder } from '../auth-url-builder';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  providers: [LoginService],
})
export class LoginComponent implements OnInit {
  isTutor: boolean;

  form = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
  });

  constructor(private statusBarService: StatusBarService, public service: LoginService, private route: ActivatedRoute) {
    this.isTutor = this.route.snapshot.data?.isTutor === true;
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get signUpUrl() {
    return AuthUrlBuilder.signUp();
  }

  get forgotPasswordUrl() {
    return AuthUrlBuilder.forgotPassword();
  }

  get rootUrl() {
    return AuthUrlBuilder.root();
  }

  async ngOnInit() {
    this.statusBarService.changeStatusBar('dark');
  }
}
