<ion-content class="ion-padding-start ion-padding-end background-pet-paws">
  <app-header [url]="rootUrl" [showBackButton]="true"></app-header>
  <app-greetings-component title="Cadastro"></app-greetings-component>

  <form [formGroup]="form" (ngSubmit)="submit()" class="ion-padding-top">
    <ion-label class="ion-padding-start" color="medium">* obrigatório</ion-label>
    <app-field label="CPF *" [control]="legal_id" [serverErrors]="service.signUpTask?.error?.items.legal_id">
      <ion-input
        class="input"
        formControlName="legal_id"
        (keyup)="checkCpf.onInputChange($event.target.value)"
        [brmasker]="{mask: '999.999.999-99', len: 14}"
      ></ion-input>
    </app-field>
    <app-field
      [disabled]="true"
      label="Nome Completo *"
      [control]="name"
      [serverErrors]="service.signUpTask?.error?.items.name"
    >
      <ion-input
        class="input"
        formControlName="name"
        disabled
        [value]="checkCpf.name$ | async"
        placeholder="Informe seu CPF"
      ></ion-input>
    </app-field>
    <app-field
      label="Como quer ser chamado?"
      [control]="nickname"
      [serverErrors]="service.signUpTask?.error?.items.nickname"
    >
      <ion-input class="input" formControlName="nickname"></ion-input>
    </app-field>
    <app-field label="E-mail" [control]="email" [serverErrors]="service.signUpTask?.error?.items.email">
      <ion-input class="input" formControlName="email"></ion-input>
    </app-field>
    <app-field label="Senha *" [control]="password1" [serverErrors]="service.signUpTask?.error?.items.password1">
      <ion-input class="input" formControlName="password1" [type]="passwordType"></ion-input>
      <div class="toogle-password" (click)="togglePasswordMode()">
        <ion-icon *ngIf="passwordType=='password'" name="eye-outline"></ion-icon>
        <ion-icon *ngIf="passwordType=='text'" name="eye-off-outline"></ion-icon>
      </div>
    </app-field>
    <ion-label class="ion-padding-start" color="medium">Crie a senha a partir de 8 dígitos</ion-label>
    <app-field
      label="Confirmar Senha *"
      [control]="password2"
      [serverErrors]="service.signUpTask?.error?.items.password2"
    >
      <ion-input class="input" formControlName="password2" [type]="passwordType"></ion-input>
      <div class="toogle-password" (click)="togglePasswordMode()">
        <ion-icon *ngIf="passwordType=='password'" name="eye-outline"></ion-icon>
        <ion-icon *ngIf="passwordType=='text'" name="eye-off-outline"></ion-icon>
      </div>
    </app-field>
    <ion-list class="ion-text-start">
      <ion-row>
        <ion-row
          class="accept-terms__container ion-align-items-center ion-margin-top ion-margin-bottom"
          style="display: flex;"
        >
          <ion-checkbox class="accept-terms__checkbox" formControlName="accept"></ion-checkbox>
          <ion-label class="accept-terms__text" color="medium"
            >Ao criar esta conta você está de acordo com os
            <a [href]="privacyPolicyUrl" target="_blank">Termos de Política e Privacidade.</a></ion-label
          >
        </ion-row>
        <ion-row class="ion-padding-start">
          <ion-text class="input-error ion-padding-start" *ngIf="accept.invalid && accept.touched" color="medium">
            <small>Você precisa aceitar os Termos de Política e Privacidade</small>
          </ion-text>
        </ion-row>
      </ion-row>
      <ion-button shape="round" size="large" type="submit" class="font-w-600 round-btn submit-btn"
        >CRIAR CONTA</ion-button
      >
    </ion-list>
  </form>
</ion-content>
