import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-field, [appField]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnInit {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() serverErrors: Array<string>;
  @Input() disabled = false;
  @Input() rows = 1;

  constructor() {}

  ngOnInit() {}

  getHeight() {
    const height = 30 + 24 * this.rows;
    return `${height.toString()}px`;
  }
}
