import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { StatusBarService } from './services/status-bar.service';
import { AccessoriesComponent } from './components/accessories/accessories.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { GridItemComponent } from './components/grid-item/grid-item.component';
import { ServerErrorsService } from '@app/shared/services/server-errors.service';
import { SkeletonListComponent } from './components/skeleton-list/skeleton-list.component';
import { GreetingsComponent } from './components/greetings-item/greetings.component';
import { HeaderComponent } from './components/header/header.component';
import { PostContentItemComponent } from './components/post-content-item/post-content-item.component';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { PipesModule } from '@app/presentation/shared/pipes/pipes.module';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, PipesModule],
  declarations: [
    HeaderSearchComponent,
    AccessoriesComponent,
    ListItemComponent,
    GridItemComponent,
    SkeletonListComponent,
    GreetingsComponent,
    HeaderComponent,
    PostContentItemComponent,
    HeaderTitleComponent,
  ],
  exports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderSearchComponent,
    AccessoriesComponent,
    SkeletonListComponent,
    GreetingsComponent,
    HeaderComponent,
    PostContentItemComponent,
    HeaderTitleComponent,
    PipesModule,
  ],
  providers: [StatusBarService, ServerErrorsService],
})
export class SharedModule {}
