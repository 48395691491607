<ion-content class="background-pet-paws ios-padding-top-safe-area">
  <ion-text class="title font-w-600">Cadastro Concluído <ion-icon name="checkmark-circle"></ion-icon></ion-text>
  <ion-text class="font-w-600">Seja bem vindo,</ion-text>
  <ion-img src="assets/images/sucess-register.png"></ion-img>
  <ion-text class="title font-w-600">
    {{service.name$ | async}}
  </ion-text>
  <ion-text class="font-w-600">O que você quer fazer?</ion-text>
  <ion-button class="round-btn font-w-600" shape="round" size="large" color="primary" [routerLink]="createPetUrl">
    CADASTRAR MEU PET
  </ion-button>
  <ion-button
    class="round-btn font-w-600"
    shape="round"
    size="large"
    color="secondary"
    [routerLink]="createDependentUrl"
  >
    CADASTRAR DEPENDENTE
  </ion-button>
  <ion-button class="main-menu round-btn font-w-600" shape="round" size="large" fill="clear" [routerLink]="homeUrl">
    Ir para área principal
  </ion-button>
</ion-content>
