import { NgModule } from '@angular/core';
import { BrMaskerModule, BrMaskDirective } from 'br-mask';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthRoutingModule } from '@auth/auth-routing.module';
import { AuthTokenInterceptor } from '@auth/auth.interceptor';
import { PasswordDefinitionComponent } from '@auth/password-definition/password-definition.component';
import { AuthRootPage } from './root/auth-root.page';
import { SignUpPage } from './sign-up/sign-up.page';
import { SignUpSuccessPage } from './sign-up-success/sign-up-success.page';
import { PartnerLoginPage } from './partner-login/partner-login.page';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppFormModule } from '@app/shared/components/form/form.module';

@NgModule({
  imports: [SharedModule, AuthRoutingModule, AppFormModule, BrMaskerModule],
  exports: [],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    PasswordDefinitionComponent,
    AuthRootPage,
    SignUpPage,
    SignUpSuccessPage,
    PartnerLoginPage,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    BrMaskDirective,
  ],
})
export class AuthModule {}
