import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '@app/data/entities/base-user.entity';
import { PartnerUserEntity } from '@app/data/entities/partner-user.entity';
import { QrCodeEntity } from '@app/data/entities/qr-code.entity';
import { TutorUserEntity } from '@app/data/entities/tutor-user.entity';
import { PartnerUserModel } from '@app/domain/models/partner-user.model';
import { TutorUserModel } from '@app/domain/models/tutor-user.model';
import { PartnerFavoritesUsecase } from '@app/domain/usecases/partner/partner-favorites.usecase';
import { AppError, ForbiddenError, UnauthorizedError } from '@app/shared/util/errors/error';
import { blobToFile } from '@app/shared/util/file';
import { Failure, Result, Success } from '@app/shared/util/types/result';
import { Storage } from '@capacitor/storage';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

type AuthUserEntity = TutorUserEntity | PartnerUserEntity;

export abstract class AuthState {
  public readonly token: string = null;
  public readonly user: AuthUserEntity = null;
  public abstract readonly resolved: boolean;
}
export class AuthInit extends AuthState {
  public readonly resolved = false;
}
export class ValidatingToken extends AuthState {
  public readonly resolved = false;
  constructor(public readonly token: string) {
    super();
    Storage.migrate();
  }
}
export class Authenticated extends AuthState {
  public readonly resolved = true;
  constructor(public readonly token: string, public readonly user: AuthUserEntity) {
    super();
  }
}
export class Unauthenticated extends AuthState {
  public readonly resolved = true;
}

interface Credentials {
  token: string;
  user: AuthUserEntity;
}
const CREDENTIALS_KEY = 'credentials';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly stateSubject: BehaviorSubject<AuthState>;
  public readonly state$: Observable<AuthState>;
  public readonly token$: Observable<string>;
  public readonly user$: Observable<AuthUserEntity>;
  public readonly tutorModel$: Observable<TutorUserModel>;

  public get state(): AuthState {
    return this.stateSubject.value;
  }
  public get user(): AuthUserEntity {
    return this.stateSubject.value.user;
  }
  public get tutor(): TutorUserEntity {
    return this.stateSubject.value.user as TutorUserEntity;
  }
  public get token(): string {
    return this.stateSubject.value.token;
  }

  public isPartner(): boolean {
    return this.user.type === UserType.partner;
  }
  public isTutor(): boolean {
    return this.user.type === UserType.tutor;
  }
  public isDependent(): boolean {
    return this.isTutor() && this.tutor.tutor.provider != null;
  }

  constructor(private httpService: HttpClient, private router: Router, private favorites: PartnerFavoritesUsecase) {
    this.stateSubject = new BehaviorSubject(new AuthInit());
    this.state$ = this.stateSubject.pipe(shareReplay(1));
    this.user$ = this.state$.pipe(map((s) => s.user));
    this.tutorModel$ = this.user$.pipe(
      filter((entity: AuthUserEntity) => entity?.type === 'tutor'),
      map((entity: TutorUserEntity) => new TutorUserModel({ entity }))
    );
    this.token$ = this.state$.pipe(map((s) => s.token));

    Promise.all([this.loadCredentials()]).then(this.handleFirstLoad.bind(this));
  }

  async login(form): Promise<Result> {
    try {
      const response = await this.httpService.post<any>(`${environment.apiUrl}/auth/login/`, form).toPromise();

      this.stateSubject.next(new ValidatingToken(response.key));

      const selfTask = await this.self();

      if (selfTask.isSuccess) {
        this.handleSelfSuccess(response.key, selfTask.data);
      }
      return selfTask;
    } catch (e) {
      return new Failure(AppError.parse(e));
    }
  }

  async signUp(form): Promise<Result> {
    try {
      const response = await this.httpService.post<any>(`${environment.apiUrl}/auth/registration/`, form).toPromise();

      this.stateSubject.next(new ValidatingToken(response.key));

      const selfTask = await this.self();

      if (selfTask.isSuccess) {
        this.handleSelfSuccess(response.key, selfTask.data);
      }
      return selfTask;
    } catch (e) {
      return new Failure(AppError.parse(e));
    }
  }

  async self(token?: string): Promise<Result<AuthUserEntity>> {
    try {
      const selfResponse = (await this.httpService
        .get(`${environment.apiUrl}/auth/user/`)
        .toPromise()) as AuthUserEntity;

      return new Success(selfResponse);
    } catch (e) {
      return new Failure(AppError.parse(e));
    }
  }

  private async handleSelfSuccess(token: string, user: AuthUserEntity) {
    const credentials = { user, token };
    await this.saveCredentials(credentials);
    this.stateSubject.next(new Authenticated(credentials.token, credentials.user));
    if (credentials.user.type === UserType.tutor) {
      await this.favorites.fetch();
    }
  }

  async logout() {
    try {
      await this.safeCleanCredentials();
      Sentry.setUser(null);
      this.stateSubject.next(new Unauthenticated());
      await this.httpService.post<any>(`${environment.apiUrl}/auth/logout/`, {}).toPromise();
    } catch (e) {}
  }

  async deleteAccount(): Promise<Result> {
    try {
      await this.httpService.delete<any>(`${environment.apiUrl}/auth/user/delete/`, {}).toPromise();

      await this.safeCleanCredentials();
      Sentry.setUser(null);
      this.stateSubject.next(new Unauthenticated());
      return new Success();
    } catch (e) {
      return new Failure(AppError.parse(e));
    }
  }

  async requestPasswordReset(email: string): Promise<Result> {
    try {
      const response = await this.httpService.post(`${environment.apiUrl}/auth/password/reset/`, { email }).toPromise();
      return new Success(response);
    } catch (e) {
      return new Failure(AppError.parse(e));
    }
  }

  async changePassword(newPassword: string, uid: string, token: string): Promise<Result> {
    try {
      const response = await this.httpService
        .post(`${environment.apiUrl}/auth/password/reset/confirm/`, {
          new_password1: newPassword,
          new_password2: newPassword,
          uid,
          token,
        })
        .toPromise();
      return new Success(response);
    } catch (e) {
      return new Failure(AppError.parse(e));
    }
  }

  async firstPassword(password: string, token: string): Promise<Result> {
    try {
      const response = await this.httpService
        .post(`${environment.apiUrl}/auth/password/change/`, {
          new_password1: password,
          new_password2: password,
          token,
        })
        .toPromise();
      return new Success(response);
    } catch (e) {
      return new Failure(AppError.parse(e));
    }
  }

  async generateQrCode(): Promise<Result<QrCodeEntity>> {
    try {
      const response = await this.httpService
        .post<QrCodeEntity>(`${environment.apiUrl}/tutor/qr_code/`, {})
        .toPromise();
      return new Success(response);
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async updatePassword(body: unknown): Promise<Result> {
    try {
      await this.httpService.post(`${environment.apiUrl}/auth/password/change/`, body).toPromise();
      return new Success();
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async updateTutorProfile(body: unknown): Promise<Result<TutorUserModel>> {
    try {
      const response = await this.httpService
        .patch<TutorUserEntity>(`${environment.apiUrl}/auth/user/`, body)
        .toPromise();
      await this.handleSelfSuccess(this.token, response);
      return new Success(TutorUserModel.decoder(response));
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async updateTutorPicture(picture: File | string): Promise<Result<TutorUserModel>> {
    try {
      let file: File;
      if (typeof picture === 'string') {
        const blob = await fetch(picture).then((r) => r.blob());
        file = blobToFile(blob, 'profile');
      } else {
        file = picture;
      }

      const form = new FormData();
      form.append('picture', file);
      const response = await this.httpService
        .patch<TutorUserEntity>(`${environment.apiUrl}/auth/user/`, form)
        .toPromise();
      await this.handleSelfSuccess(this.token, response);
      return new Success(TutorUserModel.decoder(response));
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async updatePartnerProfile(body: unknown): Promise<Result<PartnerUserModel>> {
    try {
      const response = await this.httpService
        .patch<PartnerUserEntity>(`${environment.apiUrl}/auth/user/`, body)
        .toPromise();
      await this.handleSelfSuccess(this.token, response);
      return new Success(PartnerUserModel.decoder(response));
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async cancelSubscription(): Promise<Result> {
    try {
      const response = await this.httpService.delete(`${environment.apiUrl}/subscription/`, {}).toPromise();

      let updatedUser;
      if (this.user.type === 'tutor') {
        updatedUser = {
          ...this.user,
          tutor: {
            ...this.user.tutor,
            subscription: {
              ...this.user.tutor.subscription,
              ...response,
            },
          },
        };
      } else {
        updatedUser = this.user;
      }
      await this.handleSelfSuccess(this.token, updatedUser);
      return new Success();
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  private async handleFirstLoad([credentials]: [Credentials]) {
    if (credentials == null) {
      this.stateSubject.next(new Unauthenticated());
    } else {
      // we already have login credentials
      // let's try to validate the credentials
      this.stateSubject.next(new ValidatingToken(credentials.token));
      const selfResult = await this.self(credentials.token);
      if (selfResult instanceof Success) {
        this.handleSelfSuccess(credentials.token, selfResult.data);
      } else if (selfResult.error instanceof UnauthorizedError || selfResult.error instanceof ForbiddenError) {
        await this.safeCleanCredentials();
        this.stateSubject.next(new Unauthenticated());
      } else {
        // server error or connection error, we don't need to invalidate the session
        this.stateSubject.next(new Authenticated(credentials.token, credentials.user));
      }
    }
  }

  // TODO: extract these methods that deal with Storage to a separate service

  private async saveCredentials(credentials: Credentials): Promise<void> {
    try {
      await Storage.set({ key: CREDENTIALS_KEY, value: JSON.stringify(credentials) });
      Sentry.setUser({
        email: credentials.user.email,
        id: JSON.stringify(credentials.user.id),
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async loadCredentials(): Promise<Credentials> {
    try {
      const raw = await Storage.get({ key: CREDENTIALS_KEY });

      const credentials = JSON.parse(raw.value);
      if (typeof credentials.token === 'string' && credentials.token.length > 0 && credentials.user != null) {
        return {
          token: credentials.token,
          user: credentials.user,
        };
      } else {
        await this.safeCleanCredentials();
        return null;
      }
    } catch (error) {
      await this.safeCleanCredentials();
      return null;
    }
  }

  private async safeCleanCredentials(): Promise<void> {
    try {
      await Storage.remove({ key: CREDENTIALS_KEY });
    } catch (error) {
      console.error(error);
    }
  }
}
