export class TabsUrlBuilder {
  static menu() {
    return '/app/menu';
  }

  static home() {
    return '/app/home';
  }

  static priceTracking() {
    return '/app/radarDePreco';
  }
}
