import { Injectable } from '@angular/core';
import { PartnerRepository } from '@app/data/repositories/partner.repository';
import { PartnerModel } from '@app/domain/models/partner.model';
import { BehaviorSubject } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PartnerFavoritesUsecase {
  private favoritesSubject = new BehaviorSubject<PartnerModel[]>([]);
  list$ = this.favoritesSubject.pipe(shareReplay(1));
  map$ = this.favoritesSubject.pipe(
    shareReplay(1),
    map((list) =>
      list.reduce((acc, el) => {
        acc[el.id] = true;
        return acc;
      }, {})
    )
  );

  constructor(private partnerRepository: PartnerRepository) {}

  async fetch() {
    const res = await this.partnerRepository.index(
      {
        pageSize: 100,
        favorites: true,
      },
      PartnerModel.decoder
    );
    if (res.isSuccess) this.favoritesSubject.next(res.data.results);
  }

  add(partner: PartnerModel) {
    this.favoritesSubject.next([...this.favoritesSubject.value, partner]);
  }

  remove(partner: PartnerModel) {
    const filtered = this.favoritesSubject.value.filter((p) => p.id !== partner.id);
    this.favoritesSubject.next(filtered);
  }

  async toggle(partner: PartnerModel) {
    this.addOrRemove(partner); // optimistic update
    const result = await this.partnerRepository.toggleFavorite(partner.id);
    if (result.isFailure) {
      // we must revert the optimistic update
      this.addOrRemove(partner);
    }
  }

  private addOrRemove(partner: PartnerModel) {
    const exists = this.favoritesSubject.value.find((p) => p.id === partner.id) != null;
    if (exists) {
      this.remove(partner);
    } else {
      this.add(partner);
    }
  }
}
