import { TutorUserEntity, TutorUserFromQRCodeEntity } from '@app/data/entities/tutor-user.entity';
import { BaseModel } from './model';
import { AddressEntity } from '@app/data/entities/address.entity';

export class TutorUserModel extends BaseModel<TutorUserEntity> {
  readonly formCompletion: number;
  readonly hasValidAddress: boolean;

  constructor(args: { entity: Partial<TutorUserEntity> }) {
    super(args);
    this.hasValidAddress = this.calculateHasValidAddress();
    this.formCompletion = this.calculateFormCompletion();
  }

  static decoder(entity: TutorUserEntity): TutorUserModel {
    return new TutorUserModel({ entity });
  }

  private calculateFormCompletion(): number {
    const checks = [
      this.entity.picture != null,
      this.entity.email != null,
      this.entity.tutor.pets_count != null && this.entity.tutor.pets_count > 0,
      this.hasValidAddress,
    ];

    return checks.reduce((acc, el) => {
      if (el) return acc + 1 / checks.length;
      return acc;
    }, 0);
  }

  private calculateHasValidAddress(): boolean {
    const requiredKeys: Array<keyof AddressEntity> = ['cep', 'street_name', 'city', 'district'];
    return requiredKeys.reduce((acc, key) => {
      let value;
      try {
        value = this.entity.tutor.address[key];
      } catch (e) {
        value = '';
      }
      return acc && value != null && value.toString().replace(/ /g, '').length > 0;
    }, true);
  }
}
