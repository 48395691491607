export class AuthUrlBuilder {
  static root() {
    return '/auth';
  }
  static login() {
    return '/auth/login';
  }
  static forgotPassword() {
    return '/auth/esqueceuSenha';
  }
  static signUp() {
    return '/auth/cadastro';
  }
  static signUpSuccess() {
    return '/auth/sucessoCadastro';
  }
  static partnerLogin() {
    return '/auth/login/partner';
  }
  static redefinitionPassword() {
    return '/auth/redefinicaoSenha/:uid/:token';
  }
}
