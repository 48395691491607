import { Component, Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TabsUrlBuilder } from '@app/tabs/tabs-url-builder';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input()
  url: string;

  @Input()
  showBackButton: boolean;

  constructor(private location: Location, private router: Router) {}

  goBack() {
    if (this.router.navigated) this.location.back();
    else this.router.navigateByUrl(TabsUrlBuilder.home());
  }
}
