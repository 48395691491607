import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/api/auth.service';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from '@environments/environment';
import { MenuController, ModalController, Platform, ToastController } from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import { merge, timer } from 'rxjs';
import { filter, switchMapTo, take, tap } from 'rxjs/operators';
import { GoogleApiRepository } from './data/repositories/google-api.repository';
import { FirstLoadService } from './shared/services/first-load.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private router: Router,
    private menuController: MenuController,
    public authService: AuthService,
    private zone: NgZone,
    private googleApi: GoogleApiRepository,
    private firstLoadService: FirstLoadService,
    private toastController: ToastController,
    private modalController: ModalController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {});
    App.addListener('appUrlOpen', (data) => {
      Sentry.captureMessage('appUrlOpen', {
        extra: {
          url: data.url,
        },
      });
      this.zone.run(async () => {
        Sentry.captureMessage('appUrlOpen will redirect', {
          extra: {
            url: this.handleDeeplink(data.url),
          },
        });

        await this.router.navigate([this.handleDeeplink(data.url)]);
      });
    });
    this.loadGoogleApi().then();
    this.authService.state$
      .pipe(
        filter((s) => s.resolved),
        switchMapTo(merge(this.firstLoadService.$, timer(5000))),
        take(1)
      )
      .subscribe((_) =>
        SplashScreen.hide({
          // fadeOutDuration: 1000,
        })
      );
  }

  ngOnInit() {
    setTimeout(() => {
      this.preload();
    }, 500);
  }

  private preload() {
    const preloadArea: HTMLElement = document.querySelector('.preloadArea');

    const elements = ['ion-col', 'ion-card', 'ion-input', 'ion-refresher', 'ion-spinner', 'ion-text', 'ion-toggle'];
    elements.forEach((element) => preloadArea.appendChild(document.createElement(element)));

    const toggleIOS = document.createElement('ion-toggle');
    toggleIOS.setAttribute('mode', 'ios');
    preloadArea.appendChild(toggleIOS);

    const icons = [
      'arrow-back-outline',
      'close',
      'filter',
      'filter-outline',
      'location-outline',
      'chevron-down-circle-outline',
      'search',
    ];
    icons.forEach((icon) => {
      const iconEl = document.createElement('ion-icon');
      iconEl.setAttribute('name', icon);
      preloadArea.appendChild(iconEl);
    });

    setTimeout(async () => {
      const toast = await this.toastController.create({
        message: '...',
      });
      await toast.dismiss();
      const modal = await this.modalController.create({
        component: null,
      });
      await modal.dismiss();
    });
  }

  private loadGoogleApi(): Promise<void> {
    return new Promise((resolve) => {
      // Create the script tag, set the appropriate attributes
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=${environment.googleApiToken}&callback=initMap`;
      script.defer = true;

      // Attach your callback function to the `window` object
      (window as any).initMap = () => {
        resolve();
        this.googleApi.init();
      };

      // Append the 'script' element to 'head'
      document.head.appendChild(script);
    });
  }

  private handleDeeplink(url: string): string {
    url = url.replace(/https:\/\//, '');
    return '/' + url.split('/').slice(1).join('/'); // the first chunk refers to the domain, before the first '/'
  }

  async logout() {
    await this.authService.logout();
    await this.menuController.close('custom');
    await this.router.navigate(['/'], { replaceUrl: true });
  }
}
