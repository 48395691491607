import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'root',
  templateUrl: 'root.component.html',
})
export class RootComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
