import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PartnerQuery } from '@app/domain/models/partner.query';
import { BaseRepository } from '../base/base-repository';
import { PartnerEntity } from '../entities/partner.entity';
import { Index } from '../base/index-data';
import { Result, Success, Failure } from '@app/shared/util/types/result';
import { environment } from '@environments/environment';
import { AppError } from '@app/shared/util/errors/error';
import { Decoder } from '@app/domain/base/decoder';
import { BaseModel } from '@app/domain/models/model';
import { PartnerModel } from '@app/domain/models/partner.model';
import { queryToParams } from '../base/query-helpers';

@Injectable({
  providedIn: 'root',
})
export class PartnerRepository extends BaseRepository<PartnerEntity, PartnerQuery> {
  indexUrl = 'partners/';
  indexFavoritesUrl = 'partners/favorites/';
  showUrl = 'partners/:id/';
  createUrl = 'partners/';
  editUrl = 'partners/:id/';
  deleteUrl = 'partners/:id/';
  toggleFavoriteUrl = 'partners/:id/toggle_favorite/';

  constructor(protected readonly http: HttpClient) {
    super();
  }

  async index<Model extends BaseModel>(
    query: Partial<PartnerQuery>,
    decode: Decoder<Model>
  ): Promise<Result<Index<Model>>> {
    try {
      const params = queryToParams(query);
      delete params.favorites;

      const res = await this.http
        .get<Index<PartnerEntity>>(
          `${environment.apiUrl}/${query.favorites ? this.indexFavoritesUrl : this.indexUrl}`,
          { params }
        )
        .toPromise();

      return new Success({ ...res, results: res.results.map(decode) });
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async toggleFavorite(id): Promise<Result<PartnerModel>> {
    try {
      const url = this.toggleFavoriteUrl.replace(/:id/g, id);
      const res = await this.http.post<PartnerEntity>(`${environment.apiUrl}/${url}`, {}).toPromise();
      return new Success(PartnerModel.decoder(res));
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }
}
