import { HttpClient } from '@angular/common/http';
import { Decoder } from '@app/domain/base/decoder';
import { BaseQuery } from '@app/domain/base/query';
import { BaseModel } from '@app/domain/models/model';
import { AppError, NotFoundError } from '@app/shared/util/errors/error';
import { Failure, Result, Success } from '@app/shared/util/types/result';
import { environment } from '@environments/environment';
import { HasId } from './has-id';
import { Index } from './index-data';
import { queryToParams } from './query-helpers';

interface QueryParams {
  [key: string]: string | string[];
}

export abstract class BaseRepository<
  Entity extends HasId = HasId,
  Query extends BaseQuery = BaseQuery,
  SaveArgs extends HasId = HasId
> {
  protected abstract readonly http: HttpClient;
  protected abstract readonly showUrl: string;
  protected abstract readonly indexUrl: string;
  protected abstract readonly editUrl: string;
  protected abstract readonly createUrl: string;
  protected abstract readonly deleteUrl: string;

  async show<Model extends BaseModel>(id: number, decode: Decoder<Model>): Promise<Result<Model>> {
    try {
      const baseUrl = environment.apiUrl;
      const showUrl = this.showUrl.replace(/:id/g, id.toString());
      let res = await this.http.get(`${baseUrl}/${showUrl}`).toPromise();

      // workaround for MirageJs
      if (Object.keys(res).length === 1 && res.hasOwnProperty('data')) {
        res = (res as { data: Entity }).data;
      }

      return new Success(decode(res as Entity));
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async index<Model extends BaseModel>(query: Partial<Query>, decode: Decoder<Model>): Promise<Result<Index<Model>>> {
    try {
      const res = await this.http
        .get<Index<Entity>>(`${environment.apiUrl}/${this.indexUrl}`, { params: queryToParams(query) })
        .toPromise();

      return new Success({ ...res, results: res.results.map(decode) });
    } catch (error) {
      const parsedError = AppError.parse(error);
      if (parsedError instanceof NotFoundError) {
        return new Success({ count: 0, previous: null, next: null, results: [] });
      }
      return new Failure(parsedError);
    }
  }

  async save<Model extends BaseModel>(form: SaveArgs, decode: Decoder<Model>): Promise<Result<Model>> {
    try {
      let isEdit: boolean;
      let id: string;
      if (form instanceof FormData) {
        id = form.get('id')?.toString();
        isEdit = form.get('id') != null;
      } else {
        id = form.id?.toString();
        isEdit = form.id != null;
      }
      let request, url;

      if (isEdit) {
        url = `${environment.apiUrl}/${this.editUrl}`.replace(/:id/g, id);
        request = this.http.patch<Entity>(url, form).toPromise();
      } else {
        url = `${environment.apiUrl}/${this.createUrl}`;
        request = this.http.post<Entity>(url, form).toPromise();
      }

      let res = await request;

      // workaround for MirageJs
      if (Object.keys(res).length === 1 && res.hasOwnProperty('data')) res = res.data;

      return new Success(decode(res));
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }

  async delete(id: string | number): Promise<Result> {
    try {
      await this.http.delete(`${environment.apiUrl}/${this.deleteUrl.replace(/:id/g, id.toString())}`).toPromise();
      return new Success();
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }
}
