<!-- Content -->
<ion-content class="ion-padding-start ion-padding-end ion-text-center background-pet-paws">
  <!-- Navigation: Back Button -->
  <app-header [url]="rootUrl" [showBackButton]="true"></app-header>
  <!-- Saudações -->
  <app-greetings-component
    [title]="isTutor ? 'Login' : 'Login parceiro'"
    subtitle="Bem vindo! Entre na sua conta"
  ></app-greetings-component>

  <!-- Form -->
  <form [formGroup]="form" (ngSubmit)="service.login(form.value)">
    <!-- appField -->
    <app-field label="E-mail" [control]="email" [serverErrors]="service.loginTask?.error?.items.email">
      <ion-input class="input" formControlName="email" type="email"></ion-input>
    </app-field>

    <app-field label="Senha" [control]="password" [serverErrors]="service.loginTask?.error?.items.password">
      <ion-input class="input" formControlName="password" type="password"></ion-input>
    </app-field>

    <!-- Botão de Entrar -->
    <div class="ion-margin-top ion-margin-bottom">
      <ion-button
        type="submit"
        color="primary"
        expand="block"
        shape="round"
        class="principal-btn font-w-bold ion-text-uppercase"
        [disabled]="form.invalid"
      >
        Entrar
      </ion-button>
    </div>
  </form>

  <!-- Botão de Esqueci Senha -->
  <div class="ion-margin-top ion-margin-bottom">
    <ion-button color="dark" fill="clear" class="clear-btn font-w-normal" [routerLink]="forgotPasswordUrl"
      >Esqueci minha senha</ion-button
    >
  </div>

  <!-- Botão de Cadastro -->
  <div class="ion-margin-top ion-margin-bottom" *ngIf="isTutor">
    <ion-button color="dark" fill="clear" class="clear-btn font-w-600" [routerLink]="signUpUrl"
      >Não tem uma conta? <span class="legacy-p-l-3 font-w-600 primary">Cadastre-se</span></ion-button
    >
  </div>
</ion-content>
