import { Injectable } from '@angular/core';
import { Result, Success, Failure } from '@app/shared/util/types/result';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppError } from '@app/shared/util/errors/error';

export interface CheckCpfResponse {
  name?: string;
  cpf?: string;
  detail?: string;
}

@Injectable({ providedIn: 'root' })
export class UserRepository {
  readonly checkCpfUrl = 'person/:cpf/';

  constructor(private http: HttpClient) {}

  async checkCpf(cpf: string): Promise<Result<CheckCpfResponse>> {
    try {
      const baseUrl = environment.apiUrl;
      const endpoint = this.checkCpfUrl.replace(/:cpf/, cpf.match(/\d+/g).join(''));
      const res = await this.http.get<CheckCpfResponse>(`${baseUrl}/${endpoint}`).toPromise();
      return new Success(res);
    } catch (error) {
      return new Failure(AppError.parse(error));
    }
  }
}
