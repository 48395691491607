import { Component } from '@angular/core';
import { DependentUrlBuilder } from '@app/presentation/dependent/dependent-url-builder';
import { HomeUrlBuilder } from '@app/tabs/home-tab/home-url-builder';
import { SignUpSuccessService } from './sign-up-success.service';
@Component({
  templateUrl: './sign-up-success.page.html',
  styleUrls: ['./sign-up-success.page.scss'],
  providers: [SignUpSuccessService],
})
export class SignUpSuccessPage {
  constructor(public service: SignUpSuccessService) {}

  get createPetUrl() {
    return '';
  }
  get createDependentUrl() {
    return DependentUrlBuilder.form();
  }
  get homeUrl() {
    return HomeUrlBuilder.root();
  }
}
