import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ForgotPasswordService } from '@auth/forgot-password/forgot-password.service';
import { AuthUrlBuilder } from '../auth-url-builder';

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.component.html',
  providers: [ForgotPasswordService],
})
export class ForgotPasswordComponent {
  form = new FormGroup({
    email: new FormControl(''),
  });

  constructor(public service: ForgotPasswordService) {}

  get email() {
    return this.form.get('email');
  }
  get loginUrl() {
    return AuthUrlBuilder.login();
  }
}
