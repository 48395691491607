import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@auth/login/login.component';
import { ForgotPasswordComponent } from '@auth/forgot-password/forgot-password.component';
import { NoAuthGuard } from '@app/shared/guards/no-auth.guard';
import { PasswordDefinitionComponent } from '@auth/password-definition/password-definition.component';
import { AuthRootPage } from './root/auth-root.page';
import { SignUpPage } from './sign-up/sign-up.page';
import { SignUpSuccessPage } from './sign-up-success/sign-up-success.page';
import { PartnerLoginPage } from './partner-login/partner-login.page';

const authRoutes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    children: [
      {
        path: '',
        component: AuthRootPage,
      },
      {
        path: 'login/partner',
        component: LoginComponent,
        data: {
          isTutor: false,
        },
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {
          isTutor: true,
        },
      },
      {
        path: 'esqueceuSenha',
        component: ForgotPasswordComponent,
      },
      {
        path: 'redefinicaoSenha/:uid/:token',
        component: PasswordDefinitionComponent,
      },
      {
        path: 'cadastro',
        component: SignUpPage,
      },
      {
        path: 'sucessoCadastro',
        component: SignUpSuccessPage,
      },
      {
        path: '**',
        redirectTo: '',
      },
      // {
      //   path: '',
      //   redirectTo: '/auth/root',
      //   pathMatch: 'full',
      // },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [],
})
export class AuthRoutingModule {}
