import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { version } from '../../../environments/version';

@Component({
  selector: 'app-version-banner',
  templateUrl: './version-banner.component.html',
  styleUrls: ['./version-banner.component.scss'],
})
export class VersionBannerComponent {
  readonly version = version;
  readonly environmentName = environment.name;
  shouldShowDebugTag = environment.name !== 'production';

  onRemoveDebugTag() {
    this.shouldShowDebugTag = false;
  }
}
