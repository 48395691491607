<!-- <ion-item class="field" lines="none"> -->

<div class="field-data" [ngClass]="{ disabled: disabled }" [ngStyle]="{ height: getHeight() }">
  <ng-content></ng-content>
  <ion-label [formControl]="control" ngDefaultControl>{{ label }}</ion-label>
</div>

<div class="field-errors ion-padding-start">
  <app-validation-errors *ngIf="control.invalid && control.touched; else showServerErrors" [errors]="control.errors">
  </app-validation-errors>
  <ng-template #showServerErrors>
    <app-server-errors [errors]="serverErrors"></app-server-errors>
  </ng-template>
</div>
<!-- <ion-item class="form-group" lines="none">
  <ion-label>{{ label }}</ion-label>
  <ng-content></ng-content>
</ion-item> -->

<!-- <ion-item>
  <ion-label position="stacked">{{ label }}</ion-label>
  <ng-content></ng-content>
</ion-item> -->
