import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  constructor(private platform: Platform) {}

  changeStatusBar(style: 'light' | 'dark') {
    if (this.platform.is('pwa')) {
      this.platform.ready().then(async () => {
        const lightColor = getComputedStyle(document.documentElement)
          .getPropertyValue(style === 'light' ? '--ion-color-primary-contrast' : '--ion-color-secondary')
          .trim();
        await StatusBar.setStyle({ style: style === 'light' ? Style.Light : Style.Dark });
        await StatusBar.setBackgroundColor({
          color: lightColor,
        });
      });
    }
  }
}
