<!-- Navigation: Back Button -->
<app-header [url]="loginUrl" [showBackButton]="true"></app-header>

<!-- Content -->
<ion-content class="ion-padding-start ion-padding-end ion-text-center background-pet-paws">
  <!-- Saudações -->
  <app-greetings-component title="Nova Senha" subtitle="Defina a sua nova senha"></app-greetings-component>

  <!-- Form -->
  <form [formGroup]="form" (ngSubmit)="service.submit(new_password1.value, uid, token)">
    <app-field
      label="Senha"
      [control]="new_password1"
      [serverErrors]="service.firstPasswordTask?.error?.items.new_password1"
    >
      <ion-input class="input" formControlName="new_password1" type="password"></ion-input>
    </app-field>
    <app-field
      label="Confirmar Senha"
      [control]="new_password2"
      [serverErrors]="service.firstPasswordTask?.error?.items.new_password2"
    >
      <ion-input class="input" formControlName="new_password2" type="password"></ion-input>
    </app-field>
    <ion-button
      type="submit"
      color="primary"
      expand="block"
      shape="round"
      class="principal-btn font-w-bold ion-text-uppercase"
      [disabled]="form.invalid"
    >
      Enviar
    </ion-button>
  </form>
</ion-content>
