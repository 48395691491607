import { HasId } from '../base/has-id';

export enum UserType {
  tutor = 'tutor',
  partner = 'partner',
}

export interface BaseUserEntity extends HasId {
  email: string;
  name: string;
  nickname: string;
  date_joined: string;
  type: UserType;
  picture: string;
  first_login: boolean;
  legal_id: string;
  created_at: string;
}
