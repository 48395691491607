import { PartnerEntity } from '@app/data/entities/partner.entity';
import { BaseModel } from './model';

export type RatingStar = 'RatingStar_FULL' | 'RatingStar_HALF' | 'RatingStar_EMPTY';

export class PartnerModel extends BaseModel<PartnerEntity> {
  readonly ratingStars: RatingStar[];
  readonly ratingSummary: { grade: number; ratio: number }[];
  readonly totalRatings: number;

  constructor(args: { entity: Partial<PartnerEntity> }) {
    super(args);

    const rating = this.entity?.rating || 0;
    this.ratingStars = [1, 2, 3, 4, 5].map((el) => this.evalRatingStar(el, rating));

    const ratingSummary = this.entity?.rating_summary;
    let totalRatings = ratingSummary?.count_1 || 0;
    totalRatings += ratingSummary?.count_2 || 0;
    totalRatings += ratingSummary?.count_3 || 0;
    totalRatings += ratingSummary?.count_4 || 0;
    totalRatings += ratingSummary?.count_5 || 0;
    this.totalRatings = totalRatings;

    if (totalRatings < 1) {
      this.ratingSummary = [
        { grade: 1, ratio: 0 },
        { grade: 2, ratio: 0 },
        { grade: 3, ratio: 0 },
        { grade: 4, ratio: 0 },
        { grade: 5, ratio: 0 },
      ];
    } else {
      this.ratingSummary = [
        { grade: 5, ratio: (ratingSummary?.count_5 || 0) / totalRatings },
        { grade: 4, ratio: (ratingSummary?.count_4 || 0) / totalRatings },
        { grade: 3, ratio: (ratingSummary?.count_3 || 0) / totalRatings },
        { grade: 2, ratio: (ratingSummary?.count_2 || 0) / totalRatings },
        { grade: 1, ratio: (ratingSummary?.count_1 || 0) / totalRatings },
      ];
    }
  }

  static decoder(entity: PartnerEntity): PartnerModel {
    return new PartnerModel({ entity });
  }

  private evalRatingStar(target: number, rating: number): RatingStar {
    if (rating >= target) {
      return 'RatingStar_FULL';
    } else if (rating >= target - 0.5) {
      return 'RatingStar_HALF';
    } else {
      return 'RatingStar_EMPTY';
    }
  }
}
