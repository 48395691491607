import { Injectable } from '@angular/core';
import { CheckCpfResponse, UserRepository } from '@app/data/repositories/user.repository';
import { ToastService } from '@app/shared/services/toast.service';
import { NotAcceptableError } from '@app/shared/util/errors/error';
import { Result } from '@app/shared/util/types/result';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, share, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class CheckCpfUsecase {
  private loadingElement: HTMLIonLoadingElement;

  private inputSubject = new Subject<string>();
  private busySubject = new BehaviorSubject<boolean>(false);
  name$: Observable<string> = this.inputSubject.pipe(
    share(),
    distinctUntilChanged(),
    filter((input) => typeof input === 'string' && input.length >= 14),
    debounceTime(500),
    tap(async (_) => {
      this.busySubject.next(true);
      if (this.loadingElement != null && this.loadingElement.dismiss) {
        await this.loadingElement.dismiss();
      }
      this.loadingElement = await this.loadingController.create({
        keyboardClose: false,
        message: 'Consultando...',
      });
      await this.loadingElement.present();
    }),
    switchMap((input) => from(this.userRepo.checkCpf(input))),
    map((result: Result<CheckCpfResponse>) => {
      if (result.isFailure) {
        let message;
        const genericErrorMessage = 'Não foi possível consultar o CPF';
        if (result.error instanceof NotAcceptableError) {
          message = result.error.items?.legal_id[0] || genericErrorMessage;
        } else {
          message = genericErrorMessage;
        }
        this.toastService.error(message).then();
        return '';
      }
      return result.data.name;
    }),
    tap((_) => {
      this.busySubject.next(false);
      if (this.loadingElement != null && this.loadingElement.dismiss) {
        this.loadingElement.dismiss().then();
      }
    })
  );
  busy$ = this.busySubject.asObservable();

  constructor(
    private userRepo: UserRepository,
    private toastService: ToastService,
    private loadingController: LoadingController
  ) {}

  onInputChange(input: string) {
    this.inputSubject.next(input);
  }
}
