import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/api/auth.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class SignUpSuccessService {
  public readonly name$: Observable<string>;

  constructor(public authService: AuthService) {
    this.name$ = authService.user$.pipe(
      map((user) => {
        if (user !== null) {
          if (user.nickname !== '') {
            return user.nickname;
          }
          return user.name;
        }
      })
    );
  }
}
