import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, Authenticated, Unauthenticated } from '@app/auth/api/auth.service';
import { skipWhile, tap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.state$.pipe(
      skipWhile((s) => !s.resolved),
      tap(async (s) => {
        if (s instanceof Authenticated) {
          await this.router.navigate(['/app/home'], { replaceUrl: true });
        }
      }),
      map((s) => s instanceof Unauthenticated)
    );
  }
}
