import * as Sentry from '@sentry/browser';
import { environment } from '@environments/environment';
import { version } from '@environments/version';
import { Injectable, ErrorHandler } from '@angular/core';

Sentry.init({
  dsn: 'https://47c041283e114edb86a9c436ad844806@o469730.ingest.sentry.io/5511684',
  release: `petmore-app@${environment.name}@${version}`,
  environment: environment.name,
  integrations: [
    new Sentry.Integrations.TryCatch({
      XMLHttpRequest: false,
    }),
  ],
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    Sentry.captureException(error);
    throw error;
  }
}
