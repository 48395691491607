<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="cars">
      <ion-icon name="car"></ion-icon>
      <ion-label>Carros</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="catalog">
      <ion-icon name="book"></ion-icon>
      <ion-label>Catálogo</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
