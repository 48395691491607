<ion-text class="input-error">
  <ng-container *ngFor="let errorKey of errorKeys">
    <ng-container [ngSwitch]="errorKey">
      <ng-container *ngSwitchCase="'required'">
        <small>Campo obrigatório</small>
      </ng-container>
      <ng-container *ngSwitchCase="'minlength'">
        <small>Campo muito curto [{{ errors.minlength.actualLength }}/{{ errors.minlength.requiredLength }}]</small>
      </ng-container>
      <ng-container *ngSwitchCase="'maxlength'">
        <small>Campo muito longo [{{ errors.maxlength.actualLength }}/{{ errors.maxlength.requiredLength }}]</small>
      </ng-container>
      <ng-container *ngSwitchCase="'mustMatch'">
        <small>As senhas devem ser iguais</small>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <small>Campo Inválido</small>
      </ng-container>
    </ng-container>
  </ng-container>
</ion-text>
