import { AfterViewInit, Component } from '@angular/core';
import { FirstLoadService } from '@app/shared/services/first-load.service';
import { Browser } from '@capacitor/browser';
import { AuthUrlBuilder } from '../auth-url-builder';

@Component({
  templateUrl: './auth-root.page.html',
  styleUrls: ['./auth-root.page.scss'],
})
export class AuthRootPage implements AfterViewInit {
  get signUpUrl() {
    return AuthUrlBuilder.signUp();
  }
  get loginUrl() {
    return AuthUrlBuilder.login();
  }
  get partnerLoginUrl() {
    return AuthUrlBuilder.partnerLogin();
  }

  constructor(private firstLoadService: FirstLoadService) {}

  ngAfterViewInit() {
    this.firstLoadService.registerLoad();
  }

  async onNewPartnerClick() {
    await Browser.open({
      url: 'https://abtpet.org.br/seja-parceiro/',
    });
  }
}
