<!-- Navigation: Back Button -->
<app-header [url]="loginUrl" [showBackButton]="true"></app-header>

<!-- Content -->
<ion-content class="ion-padding-start ion-padding-end ion-text-center background-pet-paws">
  <!-- Saudações -->
  <app-greetings-component
    title="Senha"
    subtitle="Esqueceu a senha? Insira seu email e enviaremos o link para alterar a senha"
  ></app-greetings-component>
  <!-- Form -->
  <form [formGroup]="form" (ngSubmit)="service.submit(email.value)">
    <app-field label="E-mail" [control]="email" [serverErrors]="service.recoverPasswordTask?.error?.items.email">
      <ion-input class="input" formControlName="email" type="email"></ion-input>
    </app-field>
    <ion-button
      type="submit"
      color="primary"
      expand="block"
      shape="round"
      class="principal-btn font-w-bold ion-text-uppercase"
      [disabled]="form.invalid"
    >
      Enviar
    </ion-button>
  </form>
</ion-content>
