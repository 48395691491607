import { HasId } from '@app/data/base/has-id';

export abstract class BaseModel<Entity extends HasId = HasId> implements HasId {
  get id() {
    return this.entity.id;
  }
  public readonly entity: Partial<Entity>;

  constructor(args: { entity: Partial<Entity> }) {
    this.entity = args.entity || {};
  }
}
