import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PasswordDefinitionService } from '@auth/password-definition/password-definition.service';
import { AuthUrlBuilder } from '../auth-url-builder';
import { mustMatchValidator } from '@app/shared/validators/must-match.validator';

@Component({
  selector: 'app-first-password',
  templateUrl: './password-definition.component.html',
  providers: [PasswordDefinitionService],
})
export class PasswordDefinitionComponent {
  form = this.fb.group(
    {
      new_password1: this.fb.control(''),
      new_password2: this.fb.control(''),
    },
    {
      validators: [mustMatchValidator('new_password1', 'new_password2')],
    }
  );
  readonly uid: string;
  readonly token: string;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, public service: PasswordDefinitionService) {
    this.uid = route.snapshot.params.uid;
    this.token = route.snapshot.params.token;
  }

  get new_password1() {
    return this.form.get('new_password1');
  }

  get new_password2() {
    return this.form.get('new_password2');
  }

  get loginUrl() {
    return AuthUrlBuilder.login();
  }
}
