import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ValidationErrorsComponent } from './validation-errors/validation-errors.component';
import { FieldComponent } from './field/field.component';
import { ServerErrorsComponent } from './server-errors/server-errors.component';

@NgModule({
  imports: [SharedModule],
  declarations: [ValidationErrorsComponent, FieldComponent, ServerErrorsComponent],
  exports: [FieldComponent, ValidationErrorsComponent, ServerErrorsComponent],
})
export class AppFormModule {}
