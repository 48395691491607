import { Injectable } from '@angular/core';
import { ToastService } from '@app/shared/services/toast.service';
import { AppError, ServerError, NotAcceptableError } from '../util/errors/error';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorsService {
  constructor(private toastService: ToastService) {}

  async showErrors(appError: AppError, fallbackMessage?: string) {
    if (appError instanceof NotAcceptableError) {
      if (appError.items.detail) {
        await this.toastService.error(appError.items.detail);
      } else if (appError.items.non_field_errors) {
        for (const message of appError.items.non_field_errors) {
          await this.toastService.error(message);
        }
      }
    } else {
      await this.toastService.error(fallbackMessage || appError.message);
    }
  }
}
