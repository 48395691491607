import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RootComponent } from './root/root.component';
import { PartnerAppUrlBuilder } from './presentation/partner-app/partner-app-url-builder';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'app',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: PartnerAppUrlBuilder.root,
    loadChildren: () => import('./presentation/partner-app/partner-app.module').then((m) => m.PartnerAppModule),
  },
  {
    path: 'root',
    component: RootComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
