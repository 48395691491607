export class DependentUrlBuilder {
  static list() {
    return '/app/dependentes';
  }
  static form() {
    return '/app/dependentes/cadastro';
  }
  static formSuccess(dependentName: string) {
    return `/app/dependentes/sucessoCadastro/${dependentName}`;
  }
  static detail(dependentId: number) {
    return `/app/dependentes/${dependentId}`;
  }
}
