export class PartnerAppUrlBuilder {
  static readonly root = 'partner-app';

  static home() {
    return `/${this.root}`;
  }
  static profile() {
    return `/${this.root}/perfil`;
  }
  static form() {
    return `/${this.root}/cadastro/passo1`;
  }
  static form2() {
    return `/${this.root}/cadastro/passo2`;
  }
  static passwordForm() {
    return `/${this.root}/cadastro/senha`;
  }
  static scan() {
    return `/${this.root}/scan`;
  }
  static privacy() {
    return `/${this.root}/privacidade`;
  }
}
