import { AppError } from '@app/shared/util/errors/error';

export abstract class Result<T = any> {
  public abstract readonly data: T;
  public abstract readonly error: AppError;
  public abstract readonly isSuccess: boolean;
  public abstract readonly isFailure: boolean;
}

export class Success<T = any> extends Result<T> {
  public readonly error = null;
  public readonly isSuccess = true;
  public readonly isFailure = false;
  constructor(public readonly data: T = null) {
    super();
  }
}

export class Failure<T = any> extends Result<T> {
  public readonly data = null;
  public readonly isSuccess = false;
  public readonly isFailure = true;
  constructor(public readonly error: AppError) {
    super();
  }
}
