<ion-header mode="md">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ng-container *ngIf="showBackButton; then showBackBtn; else showCloseBtn"></ng-container>
      <ng-template #showBackBtn>
        <ion-back-button [defaultHref]="url"></ion-back-button>
      </ng-template>
      <ng-template #showCloseBtn>
        <ion-button (click)="goBack()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ng-template>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
