import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/api/auth.service';
import { ServerErrorsService } from '@app/shared/services/server-errors.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { ToastService } from '@app/shared/services/toast.service';
import { Result } from '@app/shared/util/types/result';
import { AuthUrlBuilder } from '../auth-url-builder';

@Injectable()
export class ForgotPasswordService {
  public recoverPasswordTask: Result;

  constructor(
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private serverErrorsService: ServerErrorsService,
    private toastService: ToastService
  ) {}

  async submit(email: string) {
    const spinner = await this.spinnerService.showSpinner();
    this.recoverPasswordTask = await this.authService.requestPasswordReset(email);

    if (this.recoverPasswordTask.isSuccess) {
      await this.toastService.success('Um email foi enviado com o link de recuperação da senha.');
    } else {
      await this.serverErrorsService.showErrors(this.recoverPasswordTask.error);
    }

    await spinner.dismiss();
  }
}
