import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './api/auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.token;
    const openEndpoints = ['/auth/login/', '/auth/password/reset/', '/auth/registration/'];

    // endpoints allowed without authentication
    for (const endpoint of openEndpoints) {
      if (request.url.endsWith(endpoint)) {
        return next.handle(request);
      }
    }

    // authenticated andpoints
    if (token) {
      const headers = request.headers.set('Authorization', `Bearer ${token}`);
      request = request.clone({ headers });
    }

    return next.handle(request);
  }
}
