import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { mustMatchValidator } from '@app/shared/validators/must-match.validator';
import { StatusBarService } from '../../shared/services/status-bar.service';
import { AuthUrlBuilder } from './../auth-url-builder';
import { SignUpService } from './sign-up.service';
import { CheckCpfUsecase } from '@app/domain/usecases/user/check-cpf.usecase';
import { environment } from '@environments/environment';

@Component({
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
  providers: [SignUpService, CheckCpfUsecase],
})
export class SignUpPage implements OnInit {
  passwordType = 'password';
  rootUrl = AuthUrlBuilder.root();
  privacyPolicyUrl = environment.privacyPolicyUrl;

  form = this.fb.group(
    {
      legal_id: this.fb.control(null, [Validators.required, Validators.maxLength(14), Validators.minLength(11)]),
      email: this.fb.control(null, [Validators.required]),
      name: this.fb.control(null, [Validators.required]),
      nickname: this.fb.control(null),
      password1: this.fb.control(null, [Validators.required]),
      password2: this.fb.control(null, [Validators.required]),
      accept: this.fb.control(false, [Validators.requiredTrue]),
    },
    {
      validators: [mustMatchValidator('password1', 'password2')],
    }
  );

  constructor(
    public service: SignUpService,
    private statusBarService: StatusBarService,
    private fb: FormBuilder,
    public checkCpf: CheckCpfUsecase
  ) {}

  get legal_id() {
    return this.form.get('legal_id');
  }

  get name() {
    return this.form.get('name');
  }

  get nickname() {
    return this.form.get('nickname');
  }

  get email() {
    return this.form.get('email');
  }

  get password1() {
    return this.form.get('password1');
  }

  get password2() {
    return this.form.get('password2');
  }

  get accept() {
    return this.form.get('accept');
  }

  togglePasswordMode() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
  }

  async ngOnInit() {
    this.statusBarService.changeStatusBar('dark');
  }

  async submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      await this.service.signUp(this.form.value);
    }
  }
}
