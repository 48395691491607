import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-environment-banner',
  templateUrl: './environment-banner.component.html',
  styleUrls: ['./environment-banner.component.scss'],
})
export class EnvironmentBannerComponent {
  readonly environmentName = environment.name;
  shouldShowDebugTag = environment.name !== 'production';

  onRemoveDebugTag() {
    this.shouldShowDebugTag = false;
  }
}
