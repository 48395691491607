import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirstLoadService {
  private subject = new Subject<boolean>();
  readonly $ = this.subject.asObservable();

  registerLoad(timeout = 1000) {
    setTimeout(() => {
      this.subject.next(true);
    }, timeout);
  }
}
