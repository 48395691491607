import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/api/auth.service';
import { ServerErrorsService } from '@app/shared/services/server-errors.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { Result } from '@app/shared/util/types/result';
import { HomeUrlBuilder } from '@app/tabs/home-tab/home-url-builder';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';

@Injectable()
export class LoginService {
  public loginTask: Result;

  constructor(
    private platform: Platform,
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private serverErrorsService: ServerErrorsService
  ) {}

  async login(form: any) {
    const spinner = await this.spinnerService.showSpinner();
    this.loginTask = await this.authService.login(form);

    if (this.loginTask.isSuccess) {
      if (this.platform.is('pwa')) {
        await Keyboard.hide();
      }
      await this.router.navigate([HomeUrlBuilder.root()], { replaceUrl: true });
    } else {
      await this.serverErrorsService.showErrors(this.loginTask.error, 'Não foi possível fazer login. Tente novamente.');
    }

    await spinner.dismiss();
  }
}
